import React, { createContext, useState, useContext, useEffect} from "react"
import axios from "axios"

import { apiRoot } from "../core/shopdata"


// キャスト一覧,新人の2項
// 出勤・プロフィールは分離 (後者はprofileページでしか使わないのでそちらに載せる)

const CastDataContext = createContext()

export const useCastData = () => useContext(CastDataContext)

export default function CastDataProvider({ children }){

    const [castData, setCastData] = useState([])
    const [newfaceData, setNewfaceData] = useState([])

    const [hvnId, setHvnId]=useState(null)

    // 再取得処理 (re-fetch)
    const updateCasts = () =>{
        axios({
            method: "get",
            url: `${apiRoot}/allcast.php`
        })
        .then((res => {
            // console.log(res)
            // 一旦これ. 初回データセットでfetchするからその関数呼び出しでいいでしょう
            setCastData(
                res.data
            )
        }))
    }

    const updateNewface= () =>{

        axios({
            method: "get",
            url: `${apiRoot}/newface.php`
        })
        .then((res => {
            // console.log(res)

            setNewfaceData(res.data)
        }))
    }

    const updateHvnId=(id)=>{
        setHvnId(id)
        return null
    }

    const resetHvnId=()=>{
        setHvnId(null)
        return null
    }


    return(
        <CastDataContext.Provider value={{ 
            hvnId, updateHvnId, resetHvnId,
            castData, newfaceData, 
            updateCasts,updateNewface }}
        >
            {children}
        </CastDataContext.Provider>
    )
}
