import { Link } from "react-router-dom"
import { linkNavs } from "../../core/shopdata"

/* ナビの色変え案
    pathの先頭トリムしてidに割り当てればpath取得して対応・一意化が可.
*/

const blockNav = linkNavs.slice(0,4)
const textNav = linkNavs.slice(4)

function linksMaker(arr){

    return(
        <li><a href={arr.path}>{arr.name}</a></li>
    )
}


export function Header(){

    return(
        <header id="header-global">
            <h1 className="text-center">
                <a href="/home">
                    <img className="logo-sm" src="/img/logo-ogoto.svg" />
                </a>
            </h1>
            <nav id="g-nav-stickey">
                <ul id="header-block-nav">
                    {blockNav.map(linksMaker)}
                </ul>
                <div className="devider" />
                <ul id="header-text-nav">
                    {textNav.map(linksMaker)}
                </ul>
            </nav>

        </header>
    )
}

export default Header()
