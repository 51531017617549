import { useState, useEffect } from "react";
import { useParams } from "react-router-dom"
import { rootUri, hvnUriRoot, apiRoot } from "../core/shopdata"
import { nl2br, h2Unlinked, castBody } from "../helpers/blockmaker";

import {Swiper, SwiperSlide } from "swiper/react";
import {Autoplay} from "swiper";
import "swiper/css/bundle";
import { useCastDiaryData } from "../service/castDiary";
import { useCastData } from "../service/castData";


import axios from "axios";

// 実装テスト用の残り
// const castData = {"c_id":"165","c_code":"HIMARI","c_name":"\u3072\u307e\u308a","c_age":"24","c_body":{"t":"154","b":["77",2],"w":"56","h":"82"},"icons":{"new_f":1,"vip":0,"diary":0},"sort_f":0,"c_prof":{"c_hoby":{"title":"\u8da3\u5473","body":""},"c_charm":{"title":"\u30c1\u30e3\u30fc\u30e0\u30dd\u30a4\u30f3\u30c8","body":""},"c_like":{"title":"\u597d\u304d\u306a\u30bf\u30a4\u30d7","body":""},"c_com1":{"title":"\u672c\u4eba\u30b3\u30e1\u30f3\u30c8","body":""},"c_com2":{"title":"\u5e97\u9577\u30b3\u30e1\u30f3\u30c8","body":"VIP \u2716\u3000M\u30c3\u30c8\u3000\u2716\n\n\u5973\u795e\u964d\u81e8\n\u696d\u754c\u672a\u7d4c\u9a13\u306e\u7d20\u4eba\u3055\u3093\u3067\u3059\u3002\n\u3059\u3054\u304f\u771f\u9762\u76ee\u3067\u6027\u683c\u306e\u826f\u3044\u5973\u306e\u5b50\u3067\u3059\u3002\n\u604b\u4eba\u6c17\u5206\u6e80\u70b9\u306e\u3072\u307e\u308a\u3055\u3093\u3068\u6700\u9ad8\u306e\u3072\u3068\u6642\u3092\u304a\u904e\u3054\u3057\u304f\u3060\u3055\u3044"}},"schedule":[{"sc":true,"date":"04\/11","sc_start":"9:00","sc_end":"19:00"},{"sc":true,"date":"04\/12","sc_start":"9:00","sc_end":"16:00"},{"sc":true,"date":"04\/13","sc_start":"9:00","sc_end":"16:00"},{"sc":false,"date":"04\/14"},{"sc":false,"date":"04\/15"},{"sc":true,"date":"04\/16","sc_start":"16:00","sc_end":"24:00"},{"sc":true,"date":"04\/17","sc_start":"9:00","sc_end":"16:00"}],"c_id_hvn":null,"c_photo":[1,1,1,1,0,0,0,0,0,0]}

function castSchedule(d){
    const scTime = (d) => {
        let res = ""
        if(d.sc !==false){ res = <>{d.sc_start}<br/>~<br/>{d.sc_end}</> }
        else{ res = "-" }
        return res
    }
    
    return <li className="day"><time>{d.date}</time>{ scTime(d) }</li>
}

function profContents(profiles){
    const resMaker = (key) => {
        return (<div className="block">
                    <h3>{ profiles[key].title }</h3>
                    <p>{ nl2br(profiles[key].body) }</p>
                </div>)
    }

    if(profiles != undefined){
        return Object.keys(profiles).map((k)=>resMaker(k))
    }

}

function diaryLinkBtn($url){
    if($url){
        return(
            <div className="wrapper-link">
                <a className="btn-link link-diary"
                    href={$url}>写メ日記一覧をみる</a>
            </div>
        )
    }
}

function movieLinkBtn($url){
    if($url){
        return(
            <div className="wrapper-link">
                <a className="btn-link link-mov"
                    href={$url}>動画をみる</a>
            </div>
        )
    }
}

function reserveBtn(hvnId){

    let id =""
    let linkTxt= "キャストを予約する"
    if(hvnId){
        id= `?girl_id=${hvnId}`
    }

    return(
        <nav className="wrapper-link">
            <a className="btn-link reserve" 
                href={`${hvnUriRoot}/A6ShopReservation/${id}`} target="_blank" rel="noreferrer noopner">{linkTxt}</a>
        </nav>
    )
}


function diaryBlock(diaryData){

    const  diaryArticle = (json)=>{

            let imgPath = "/img/nophoto-diary.jpg"
            if(json.url_img != "null"){ imgPath = json.url_img}

            return (
                <li key={json.url_img} className="content">
                    <a href={json.url_link} target="_blank" rel="noreferrer noopner">
                        <div className="wrappar-img"><img src={imgPath} /></div>
                        <h3>{json.title}</h3>
                    </a>
                </li>)}

    if(diaryData){

        return(
            <section className="block-diary">
                {h2Unlinked("写メ日記")}
                    <ul className="diary-wrappar">
                        { diaryData.map((d)=> diaryArticle(d)) }
                    </ul>
            </section>
        )
    } 
}



export default function Profile(){

    const { castId } = useParams()
    const [castData, setcastData] = useState(null)
    const {castDiaryData, getCastDiary} = useCastDiaryData()
    const {hvnId, updateHvnId, resetHvnId} = useCastData()


    useEffect(()=>{

        const fetchCastData = (id) => {
            axios({
                method:"get",
                url: `${apiRoot}/profile.php?c=${id}`
            })
            .then(res => {
                // console.log(res)
                setcastData(res.data)
                if(res.data.url_diary){
                    getCastDiary(res.data.url_diary)
                    updateHvnId(res.data.c_id_hvn)
                }
            })
        } 

        fetchCastData(castId)

        return()=>{
            resetHvnId()
        }

    },[castId])

    const slideKeys = [0,1,2,3,4,5,6,7,8,9];
 
    console.log(castId)
    console.log(castData)
    return(
        <main id="profile">
            <article>
                <Swiper
                            modules={[Autoplay]}
                            loop={true}
                            slidesPerView={1.2}
                            autoplay={true}
                            centeredSlides={true}
                            breakpoints={{ 560:{slidesPerView:3},}}
                        >
                            
                            { slideKeys.map((val) => {
                                if(castData && castData.c_photo[val] == 1){
                                    return <SwiperSlide key={val}><img src={ `${rootUri}/d/p${castData.c_id}_${1+val}.jpg`} /></SwiperSlide>
                                }
                            })}
                </Swiper>
                <div className="devider"></div>
                
                <div className="common-mb wrapper-txt text-center">
                    <h2 className="text-center text-primary">
                        {castData
                            ? <>{castData.c_name} ({castData.c_age})</>
                            : <></>
                        }
                    </h2>
                    {castData && castBody(castData.c_body)}
                </div>
                {h2Unlinked("プロフィール")}
                <div className="common-mb">
                    {castData && profContents(castData.c_prof)}
                </div>
                {castDiaryData.length 
                        ? diaryBlock(castDiaryData)
                        : null}
                {castData && diaryLinkBtn(castData.url_diary)}
                {castData && movieLinkBtn(castData.url_mov)}
            </article>

            {h2Unlinked("出勤情報")}
            <div className="common-mb">
                <ul className="wrapper-schedule">{ castData && castData.schedule.map(sc=>castSchedule(sc))}</ul>
            </div>
            {reserveBtn(hvnId)}
        </main>
    )
}